import type { DeviceInfo } from "@wolzienllc/vcc-react-commons";
import type { NetworkDiagnosticOptions } from "@wolzienllc/vcc-react-commons";

export const SET_CALL_ENDED = "SET_CALL_ENDED";
export const SET_CALL_STARTED = "SET_CALL_STARTED";
export const SET_DEVICE_INFO = "SET_DEVICE_INFO";
export const SET_ERROR = "SET_ERROR";
export const SET_INITIALIZED = "SET_INITIALIZED";
export const SET_NETWORK_DIAGNOSTIC_PARAMS = "SET_NETWORK_DIAGNOSTIC_PARAMS";
export const SET_PAGE_CONFIG = "SET_PAGE_CONFIG";
export const SET_PARTICIPANT_DATA = "SET_PARTICIPANT_DATA";
export const SET_QUEUE_STATE = "SET_QUEUE_STATE";
export const SET_ZOOM_MEETING_LINK = "SET_ZOOM_MEETING_LINK";
export const SET_NEXTOMEET_GUEST_LINK = "SET_NEXTOMEET_GUEST_LINK";

interface CallConfig {
  remoteParticipantVolume?: number;
  remotePresenterVolume?: number;
}

export interface CallData {
  accessToken?: string;
  accountName: string;
  chatApp: ChatApp;
  config: CallConfig;
}

export type ChatApp = "gruveo" | "twilio" | "facetime" | "zoom" | "nextomeet";

export interface NetworkDiagnosticParams {
  token: string;
  options: NetworkDiagnosticOptions;
}

export enum PageErrorType {
  InvitationCancelled = 0,
  InvitationInUse = 1,
  InvitationInvalid = 2,
}

export interface PageError {
  type?: PageErrorType;
  message?: string;
}

export type QueueState =
  | "qAssigned"
  | "qCalled"
  | "qCalling"
  | "qNot"
  | "qReady"
  | "qUnassigned";

export type ParticipantContactType = "email" | "facetime" | "phone";

export interface ParticipantContact {
  guid: string;
  type: ParticipantContactType;
  value: string;
}

export interface Participant {
  guid: string;
  fullName: string;
  contacts: ParticipantContact[];
}

export interface PageState {
  callData?: CallData;
  deviceInfo?: DeviceInfo;
  error?: PageError;
  initialized: boolean;
  networkDiagnosticParams: NetworkDiagnosticParams | null;
  nextomeetGuestUrl?: string;
  pageConfig?: PageConfiguration;
  participantData?: Participant;
  queueState: QueueState;
  zoomMeetingLink?: string;
}

export interface PageConfiguration {
  appearance: {};
  content: {
    callingMessage: string;
    callingAccountMessage: string;
    copyright: string;
    duplicateConnection: string;
    errorHeading: string;
    facetimeForm?: {
      correctConfirmation: string;
      fieldPlaceholder: string;
      question: string;
    };
    invitationCancelled: string;
    navbarTitle: string;
    participantAssignedBody: string;
    participantAssignedHeading: string;
    participantNotExpected: string;
    rotateDeviceText: string;
    termsAndConditionsRequired?: string;
    thankYouHeading: string;
    thankYouBody: string;
    tipsTitle: string;
    /** @deprecated no longer rendered */
    tips?: string[];
  };
  options: {
    doesRequireTermsAgreement: boolean;
    shouldRequestFaceTime: boolean;
  };
}

interface SetCallEndedAction {
  type: typeof SET_CALL_ENDED;
}

interface SetCallStartedAction {
  type: typeof SET_CALL_STARTED;
  payload: CallData;
}

interface SetDeviceInfoAction {
  type: typeof SET_DEVICE_INFO;
  payload: DeviceInfo;
}

interface SetErrorAction {
  type: typeof SET_ERROR;
  payload?: PageError;
}

interface SetInitializedAction {
  type: typeof SET_INITIALIZED;
}

interface SetNetworkDiagnosticParamsAction {
  type: typeof SET_NETWORK_DIAGNOSTIC_PARAMS;
  payload: NetworkDiagnosticParams;
}

interface SetPageConfigAction {
  type: typeof SET_PAGE_CONFIG;
  payload: PageConfiguration;
}

interface SetParticipantDataAction {
  type: typeof SET_PARTICIPANT_DATA;
  payload: Participant;
}

interface SetQueueStateAction {
  type: typeof SET_QUEUE_STATE;
  payload: QueueState;
}

interface SetZoomMeetingLinkAction {
  type: typeof SET_ZOOM_MEETING_LINK;
  payload: string;
}

interface SetNextomeetGuestLinkAction {
  type: typeof SET_NEXTOMEET_GUEST_LINK;
  payload: string;
}

export type PageActionType =
  | SetCallEndedAction
  | SetCallStartedAction
  | SetDeviceInfoAction
  | SetErrorAction
  | SetInitializedAction
  | SetNetworkDiagnosticParamsAction
  | SetPageConfigAction
  | SetParticipantDataAction
  | SetQueueStateAction
  | SetZoomMeetingLinkAction
  | SetNextomeetGuestLinkAction;
