import type { FunctionComponent } from "react";
import { PageError, PageErrorType } from "../../state/types";

import { useSelector } from "react-redux";
import { Alert, AlertTitle } from "@mui/material";
import { RootState } from "../../state";

type Props = {
  error: PageError;
};

const ErrorAlert: FunctionComponent<Props> = (props) => {
  const { error } = props;
  const { errorHeading, invitationCancelled, participantNotExpected } =
    useSelector((state: RootState) => state.pageConfig.content);

  let errorBody = "";
  if (error.type === PageErrorType.InvitationCancelled) {
    errorBody = invitationCancelled;
  } else if (
    error.type === PageErrorType.InvitationInUse ||
    error.type === PageErrorType.InvitationInvalid
  ) {
    errorBody = participantNotExpected;
  }

  return (
    <Alert
      className="fullWidth alert-margin"
      color="error"
      icon={false}
      severity="error"
      sx={{
        display: "block",
        textAlign: "center",
      }}
    >
      <AlertTitle sx={{ fontSize: "2rem" }}>{errorHeading}</AlertTitle>
      {errorBody}
    </Alert>
  );
};

export default ErrorAlert;
