import type { FunctionComponent } from "react";
import { useState } from "react";

type Props = {
  guestUrl?: string;
};

const NextomeetEmbed: FunctionComponent<Props> = (props) => {
  const { guestUrl = undefined } = props;

  const [loading, setLoading] = useState<boolean>(true);

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {loading && (
        <div style={{ height: "99%", width: "98%" }}>
          {guestUrl ? "Joining call ..." : "Loading ..."}
        </div>
      )}
      {guestUrl && (
        <iframe
          allow="camera;microphone"
          id="nextomeet-embed-iframe"
          src={guestUrl}
          style={
            loading
              ? { display: "none" }
              : { width: "100%", height: "100%", border: "0px" }
          }
          onLoad={() => {
            setLoading(false);
          }}
        />
      )}
    </div>
  );
};

export default NextomeetEmbed;
