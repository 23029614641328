import type { FunctionComponent } from "react";
import { withOrientationChange } from "react-device-detect";
import { shallowEqual, useSelector } from "react-redux";
import { Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { RootState } from "../../state";
import TipsSlideShow from "./TipsSlideShow";

const useStyles = makeStyles({
  root: {
    alignItems: "center",
    backgroundColor: "#cce8cd",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "90%",
    padding: 10,
    width: "90%",
  },
});

type Props = {
  isLandscape?: boolean;
};

const Tips: FunctionComponent<Props> = (props) => {
  const { isLandscape } = props;
  const classes = useStyles();

  const { tips = [], tipsTitle = "" } = useSelector(
    (state: RootState) => state.pageConfig.content,
    shallowEqual
  );

  return (
    <Paper className={classes.root} elevation={2}>
      <Typography
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: isLandscape ? "row" : "column",
          justifyContent: isLandscape ? undefined : "flex-start",
          margin: "4px",
        }}
        variant="h5"
      >
        {tipsTitle}
      </Typography>
      <TipsSlideShow isLandscape={isLandscape} />
    </Paper>
  );
};

export default withOrientationChange(Tips);
