import React from "react";
import { Link } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <footer className="text-center" id="footer">
      <Link
        href="https://www.nextologies.com/vcc-services.html"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          alt="AirFirst logo"
          src="/img/vcc_nextologies.png"
          style={{
            height: "4.5dvh",
            width: "auto",
          }}
        />
      </Link>
    </footer>
  );
};

export default Footer;
