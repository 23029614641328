import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import ContentAreaText from "./ContentAreaText";
import Embed from "./Embed";
import { RootState } from "../state";
import Footer from "./Footer";
import NetworkDiagnostic from "./NetworkDiagnostic";
import { useSetDisplayComponent } from "../utilities/customHooks";
import { isAfter } from "date-fns"; // TODO: Remove after Twilio EOL (12/05/2026)

type Props = {
  isLandscape: boolean;
};

const NORMAL_WIDTH = "75%";
const MOBILE_LANDSCAPE_WIDTH = "99%";
const MOBILE_PORTRAIT_WIDTH = "90%";

const NORMAL_HEIGHT = "100%";
const MOBILE_LANDSCAPE_HEIGHT = "99%";
const MOBILE_PORTRAIT_HEIGHT = "45%";

const twilioSoftShutoffDate = new Date("2026-10-31T11:59:59"); // TODO: Remove after Twilio EOL (12/05/2026)
const today = new Date(); // TODO: Remove after Twilio EOL (12/05/2026)

// TODO: Remove after Twilio EOL (12/05/2026)
/**After 11/01/2026, functionality for Tenaciti testing and the option for "VCC WebRTC" will be automatically disabled or removed from display due to EOL of Twilio support. */
const isAfterTwilioSoftShutoffDate = isAfter(today, twilioSoftShutoffDate);

const Home: FunctionComponent<Props> = (props) => {
  const { isLandscape } = props;

  const { callData, networkDiagnosticParams, queueState } = useSelector(
    (state: RootState) => state
  );

  const displayAlert = useSetDisplayComponent(callData, isLandscape, isMobile);

  const [embedHeight, setEmbedHeight] = useState<string>(NORMAL_HEIGHT);
  const [embedWidth, setEmbedWidth] = useState<string>(NORMAL_WIDTH);
  const [runDiagnostics, setRunDiagnostics] = useState<boolean>(
    isAfterTwilioSoftShutoffDate
      ? false
      : ["qAssigned", "qNot", "qReady"].includes(queueState)
  );

  useEffect(() => {
    if (isMobile && isLandscape) {
      setEmbedWidth(MOBILE_LANDSCAPE_WIDTH);
      setEmbedHeight(MOBILE_LANDSCAPE_HEIGHT);
    } else if (isMobile && !isLandscape) {
      setEmbedWidth(MOBILE_PORTRAIT_WIDTH);
      setEmbedHeight(MOBILE_PORTRAIT_HEIGHT);
    } else {
      setEmbedWidth(NORMAL_WIDTH);
      setEmbedHeight(NORMAL_HEIGHT);
    }
  }, [isMobile, isLandscape]);

  useEffect(() => {
    if (!isAfterTwilioSoftShutoffDate) {
      if (callData) {
        setRunDiagnostics(false);
      } else {
        if (networkDiagnosticParams) {
          setRunDiagnostics(
            /*["qAssigned", "qCalled", "qNot", "qReady"].includes(queueState) // TODO: Change server to set user back to qReady on disconnect event if not in call*/
            queueState !== "qCalling"
          );
        } else {
          setRunDiagnostics(false);
        }
      }
    }
  }, [
    callData,
    isAfterTwilioSoftShutoffDate,
    networkDiagnosticParams,
    queueState,
    setRunDiagnostics,
  ]);

  /*// Testing only
  useEffect(() => {
    console.log(`Current queueState: ${queueState ?? "unknown"}`);

    return () => {
      /* Do nothing /
    };
  }, [queueState]);*/

  return (
    <main>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: isLandscape ? "center" : "flex-start",
          width: "100%",
        }}
      >
        {displayAlert && <ContentAreaText />}
        {!isAfterTwilioSoftShutoffDate &&
          !callData &&
          networkDiagnosticParams && (
            <NetworkDiagnostic
              isLandscape={isLandscape}
              networkDiagnosticParams={networkDiagnosticParams}
            />
          )}
        {callData && (
          <div style={{ height: embedHeight, width: embedWidth }}>
            <Embed
              callData={callData}
              data-testid="Embed"
              inDisconnectedCalling={false}
              isLandscape={isLandscape}
            />
          </div>
        )}
      </div>
      <Footer />
    </main>
  );
};

export default Home;
