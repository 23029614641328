import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import EventListener from "react-event-listener";
import GruveoEmbed from "@wolzienllc/gruveo-embed-component";
import TwilioEmbed, { Perspective } from "@wolzienllc/twilio-embed-component";
import { apiClient } from "../utilities/apiClient";
import { SERVER_ROOT } from "../utilities/constants";
import { CallData } from "../state/types";
import { RootState } from "../state";
import { useSelector } from "react-redux";
import ZoomEmbed from "./ContentAreaText/ZoomEmbed";
import NextomeetEmbed from "./ContentAreaText/NextomeetEmbed";

type Props = {
  callData: CallData;
  inDisconnectedCalling: boolean;
  isLandscape: boolean;
};

const Embed: React.FC<Props> = (props) => {
  const { callData, isLandscape } = props;
  const {
    accessToken,
    chatApp,
    accountName: roomName,
    config: { remoteParticipantVolume, remotePresenterVolume },
  } = callData;
  const [config, setConfig] = useState<any>(null);

  const { zoomMeetingLink, nextomeetGuestUrl } = useSelector(
    (state: RootState) => state
  );

  useEffect(() => {
    if (chatApp === "gruveo") {
      (async () => {
        try {
          const config = await apiClient
            .get("chatApp/config", {
              searchParams: {
                chatApp,
              },
            })
            .json();

          setConfig(config);
        } catch (err) {
          console.warn(err);
        }
      })();
    } else if (!chatApp) {
      setConfig(null);
    }
  }, [chatApp]);

  useEffect(() => {
    if (isMobile && isLandscape) {
      const element = document.getElementById("footer");
      element?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }

    return () => {};
  }, [isMobile, isLandscape]);

  const handleMessage = (e) => {
    const key = e.message ? "message" : "data";
    const data = e[key];

    if (data === "gruveoEmbed State: ready" && props.inDisconnectedCalling) {
      //eslint-disable-next-line
      location.reload();
    }
  };

  const signerURL = `${SERVER_ROOT}/signer`;

  return (
    <React.Fragment>
      {chatApp === "gruveo" && config && (
        <GruveoEmbed
          config={config}
          data-testid="GruveoEmbed"
          roomName={roomName}
          signerURL={signerURL}
        />
      )}
      {chatApp === "twilio" && accessToken && (
        <TwilioEmbed
          accessToken={accessToken}
          data-testid="TwilioEmbed"
          perspective={Perspective.participant}
          remoteParticipantVolume={remoteParticipantVolume}
          remotePresenterVolume={remotePresenterVolume}
          roomName={roomName}
        />
      )}
      {callData && chatApp === "zoom" && (
        <ZoomEmbed meetingLink={zoomMeetingLink} />
      )}
      {callData && chatApp === "nextomeet" && (
        <NextomeetEmbed guestUrl={nextomeetGuestUrl} />
      )}
      <EventListener target="window" onMessage={handleMessage} />
    </React.Fragment>
  );
};

export default Embed;
